// Override default variables before the import

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

$lightGray: #ccc;
$darkGray: #666;
$CatalanRed: #db131a;

.navbar {
  padding: 10px 0;
  box-shadow: 0 2px 4px 1px rgba($color: #000000, $alpha: .2);
  background-color: $CatalanRed;

  .search-bar {
    width: 100%;
  }
}

.main-layout {
  padding-top: 20px;
}

.loading-screen {
  text-align: center;
  padding: 50px;
}

.no-results {
  padding: 50px;
}