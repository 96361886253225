@import '../../styles/style.scss';

.definition-header {
  // border-bottom: 1px solid $lightGray;
  padding-bottom: 20px;
}

.nav-link {
  background-color: transparent;
  font-weight: bold;
}

.gender-marker {
  margin-left: 5px;
}

.citation {
  @extend .text-muted;
  font-style: italic;
  font-size: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.translation-group {
  margin-top: 20px;

  .translation-heading {
    padding-bottom: 10px;
  }

  li {
    margin-bottom: 20px;
  }

  .translation-word {
    font-weight: bold;
  }

  .variations {
    padding-left: 10px;
    li {
      margin-top: 5px;
      margin-bottom: 0;
    }
  }

  .example {
    padding-bottom: 10px;
  }

  .alert-light {
    border-color: #ccc;
  }
}

.category-label {
  margin-left: 10px;
  text-transform: capitalize;
}

.expressions-section {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid $lightGray;

  .expressions-list {
    padding-left: 0;
  }

  .expression {
    list-style-type: none;
    margin-top: 20px;
  }

  .expression-text {
    font-style: italic;
    font-weight: bold;
  }
}

.conjugations {

  .mode-title {
    font-size: 20px;
    padding-bottom: 10px;
    padding-top: 30px;
    font-weight: bold;
  }

  .tense-title {
    font-weight: bold;
    padding-bottom: 10px;
    padding-top: 15px;
  }

  .conjugation-row {
    font-size: 12px;
  }

  .subject {
    width: 150px;
  }
}

.examples {

  .highlight {
    font-weight: bold;
  }

  td {
    @extend .text-muted;
  }

  .play-icon {
    padding-right: 20px;
    padding-left: 0;
  }
}
