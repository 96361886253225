@import '../../styles/style.scss';


.word {
  text-align: center;
  font-size: 108px;
  padding: 40px;
}

.buttons {
  text-align: center;
  margin-top: 20px;

  .btn {
    margin-left: 10px;
    margin-right: 10px;
    // fix width (relative to font-size) so the buttons
    // don't bounce around as text changes within
    width: 10em;
  }
}

.elapsed-time {
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;
  font-family: monospace;
}